<template>
  <ValidationProvider
    v-slot="{ errors, validate: _validate }"
    :events="events"
    :name="fieldName"
    :rules="extendedRules"
    tag="div"
  >
    <v-select
      ref="select"
      v-model="modelValue"
      :data-anonymize="anonymize"
      :error-messages="errors"
      :name="fieldName"
      :required="required"
      v-bind="$attrs"
      persistent-hint
      v-on="$listeners"
      @click="logOnClick($event, value)"
      @blur="logOnBlur($event, value)"
      @focus="logOnFocus($event, value)"
      @change="
        onChange(_validate);
        logOnChange($event);
      "
    >
      <template #item="{ item }">
        <slot :item="item" name="item">
          <div
            :class="`${fieldName}__option--${useIndex ? item.index : item[itemValue]}`"
            class="cw-select__item"
            v-text="itemText(item)"
          />
        </slot>
      </template>

      <template #selection="{ item }">
        <slot :item="item" name="selection">
          <div
            :class="`${fieldName}__selection`"
            class="cw-select__item"
            v-text="itemText(item)"
          />
        </slot>
      </template>

      <slot/>
    </v-select>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CwSelect',

  components: {
    ValidationProvider,
  },

  inheritAttrs: false,

  props: {
    anonymize: {
      type: Boolean,
      default: false,
    },

    events: {
      type: [String, Array],
      default: 'change',
    },

    rules: {
      type: String,
      default: '',
    },

    useIndex: {
      type: Boolean,
      default: false,
    },

    // must be included in props
    value: {
      type: null,
      required: true,
    },
  },

  data: () => ({
    modelValue: '',
  }),

  computed: {
    ...mapGetters({
      apiErrors: 'application/apiErrors',
      initialData: 'application/getInitialData',
    }),

    /*
     * Extends the rules set with api errors
     */
    extendedRules() {
      const delimiter = !this.rules ? '' : '|';
      return `${this.rules}${delimiter}error:${this.hasApiError}`;
    },

    fieldName() {
      return this.$attrs.id;
    },

    hasApiError() {
      return this.apiErrors.includes(this.fieldName);
    },

    initialValue() {
      return this.initialData[this.fieldName] || this.value;
    },

    itemValue() {
      return this.$attrs['item-value'] || 'value';
    },

    preventSetData() {
      return Object.keys(this.$attrs).includes('prevent-set-data');
    },

    required() {
      return this.rules.split('|').includes('required');
    },
  },

  watch: {
    // Handles internal model changes.
    modelValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.modelValue = newVal;
    },
  },

  created() {
    this.setInitialValue();
  },

  methods: {
    ...mapActions({
      setData: 'application/setData',
      removeApiError: 'application/removeApiError',
    }),

    getElementData(value) {
      return {
        targetId: this.fieldName,
        name: this.fieldName,
        nodeName: 'SELECT',
        type: 'select-one',
        value: String(value),
      };
    },

    itemText(item) {
      if (typeof this.$attrs['item-text'] === 'function') return this.$attrs['item-text'](item);
      return item[this.$attrs['item-text'] || 'text'];
    },

    logOnBlur(event = { type: 'blur' }, value) {
      const data = this.getElementData(value);

      this.$eventLogger.vuetifyEvent(event, data, { anonymize: this.anonymize });
    },

    logOnChange(value) {
      const data = this.getElementData(value);

      this.$eventLogger.vuetifyEvent({ type: 'change' }, data, { anonymize: this.anonymize });
    },

    logOnClick(event = { type: 'click' }, value) {
      const data = this.getElementData(value);

      this.$eventLogger.vuetifyEvent(event, data, { anonymize: this.anonymize });
    },

    logOnFocus(event = { type: 'focus' }, value) {
      const data = this.getElementData(value);

      this.$eventLogger.vuetifyEvent(event, data, { anonymize: this.anonymize });
    },

    setInitialValue() {
      this.modelValue = this.initialValue;
    },

    async onChange(validate) {
      this.removeApiError(this.fieldName);

      await this.$nextTick();
      const { valid } = await this.validate(validate);

      if (!valid || this.preventSetData) return;

      await this.submit();

      // Validation after HTTP request is required so that
      // potential API errors are rendered in UI
      await this.validate(validate);
    },

    async validate(validate) {
      const response = await validate();

      this.$emit('validate', response);

      return response;
    },

    async submit() {
      const params = {
        [this.fieldName]: this.modelValue,
      };

      await this.setData(params);
    },
  },
};
</script>

<style lang="scss">
.cw-select {
  &__item {
    width: 100%;
  }
}
</style>
