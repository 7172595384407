var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"events":_vm.events,"name":_vm.fieldName,"rules":_vm.extendedRules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var _validate = ref.validate;
return [_c('v-select',_vm._g(_vm._b({ref:"select",attrs:{"data-anonymize":_vm.anonymize,"error-messages":errors,"name":_vm.fieldName,"required":_vm.required,"persistent-hint":""},on:{"click":function($event){return _vm.logOnClick($event, _vm.value)},"blur":function($event){return _vm.logOnBlur($event, _vm.value)},"focus":function($event){return _vm.logOnFocus($event, _vm.value)},"change":function($event){_vm.onChange(_validate);
      _vm.logOnChange($event);}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_vm._t("item",function(){return [_c('div',{staticClass:"cw-select__item",class:(_vm.fieldName + "__option--" + (_vm.useIndex ? item.index : item[_vm.itemValue])),domProps:{"textContent":_vm._s(_vm.itemText(item))}})]},{"item":item})]}},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_vm._t("selection",function(){return [_c('div',{staticClass:"cw-select__item",class:(_vm.fieldName + "__selection"),domProps:{"textContent":_vm._s(_vm.itemText(item))}})]},{"item":item})]}}],null,true),model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},'v-select',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }